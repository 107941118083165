//
// Wizard 6
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import '../../init';

.wizard.wizard-6 {
  // Content
  .wizard-content {
    // Wizard Nav
    .wizard-nav {
      padding: 0;

      // Steps
      .wizard-steps {
        display: flex;
        align-items: center;

        // Step
        .wizard-step {
          padding: 0.75rem 0;
          transition: $transition-link;
          margin-bottom: 1.5rem;

          .wizard-wrapper {
            display: flex;
            align-items: center;
          }

          .wizard-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition-link;
            min-width: 46px;
            height: 46px;
            border-radius: 12px;
            background-color: $gray-100;
            margin-right: 1rem;

            .wizard-check {
              display: none;
              font-size: 1.4rem;
            }

            .wizard-number {
              font-weight: 600;
              color: $dark-75;
              font-size: 1.35rem;
            }
          }

          .wizard-label {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .wizard-title {
              color: $dark;
              font-weight: 600;
              font-size: 1.24rem;
            }

            .wizard-desc {
              color: $text-muted;
              font-size: $font-size-sm;
            }
          }

          // Current States
          &[data-wizard-state='current'] {
            transition: $transition-link;

            .wizard-icon {
              transition: $transition-link;
              background-color: $success-light;

              .wizard-check {
                color: $success;
                display: none;
              }

              .wizard-number {
                color: $success;
              }
            }

            .wizard-label {
              .wizard-title {
                color: $dark;
              }

              .wizard-desc {
                color: $gray-500;
              }
            }
          }

          // Done States
          &[data-wizard-state='current']:last-child,
          &[data-wizard-state='done'] {
            .wizard-icon {
              transition: $transition-link;
              background-color: $success-light;

              .wizard-check {
                color: $success;
                display: inline-block;
              }

              .wizard-number {
                display: none;
              }
            }

            .wizard-label {
              .wizard-title {
                color: $text-muted;
              }

              .wizard-desc {
                color: $gray-400;
              }
            }
          }
        }
      }
    }
  }
}

// Mobile mode
@include media-breakpoint-down(sm) {
  .wizard.wizard-6 {
    // Container
    .wizard-content {
      // Wizard
      .wizard-nav {
        // Steps
        .wizard-steps {
          .wizard-step {
            width: 100%;

            .wizard-wrapper {
              .svg-icon {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
