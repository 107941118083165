.visit-sheet-order {
  ul {
    list-style: none;
    li {
      width: 100%;
      position: relative;
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 18px 20px;
      margin: 18px 0px;
      background-color: #fff;
      box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
      outline: none;
      border-radius: calc(4px / var(--scale-x, 1));
      box-sizing: border-box;
      list-style: none;
      transform-origin: 50% 50%;
      -webkit-tap-highlight-color: transparent;
      color: #333;
      font-weight: 400;
      font-size: 1rem;
      white-space: nowrap;
      transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    }
  }
}
