.dropZone-edit{
  background-size: cover;
  background-position: center;
  height: 300px;
  border-radius: 2rem;
  img{
    object-fit: cover;
  }
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0008;
    z-index: 1;
  }
  &__btn{
    width: 50px;
    height: 50px;
    outline: none;
    border-radius: 50%;
    border: none;
    z-index: 2;
  }
  @media (min-width: 1200px) {
    height: 450px;
  }
}

.dropZone{
  background-size: cover;
  background-position: center;
  height: 300px;
  border-radius: 2rem;
  img{
    object-fit: cover;
  }
  @media (min-width: 1200px) {
    height: 450px;
  }
}