//
// Wizard 1
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import '../../init';

// Base
.wizard.wizard-1 {
  // Nav
  .wizard-nav {
    // Steps
    .wizard-steps {
      display: flex;
      justify-content: center;
      align-items: center;

      // Step
      .wizard-step {
        display: flex;
        align-items: center;
        text-align: center;

        .wizard-label {
          margin-left: 3.5rem;
          margin-right: 3.5rem;

          .wizard-icon {
            color: $text-muted;
            font-size: 3.75rem;

            @include svg-icon-color($text-muted);
          }

          .wizard-title {
            color: $dark-50;
            font-size: 1.1rem;
            font-weight: 500;
            margin-top: 0.75rem;
          }
        }

        .wizard-arrow {
          color: $dark-50;
          font-size: 1.25rem;

          &.last {
            display: none;
          }
        }

        &[data-wizard-state='done'],
        &[data-wizard-state='current'] {
          .wizard-label {
            .wizard-icon {
              color: $primary;

              @include svg-icon-color($primary);
            }

            .wizard-title {
              color: $primary;
            }
          }
          .wizard-arrow {
            color: $primary;

            @include svg-icon-color($primary);
          }
        }
      }
    }
  }
}

// Desktop Mode
@include media-breakpoint-between(md, lg) {
  .wizard.wizard-1 {
    // Nav
    .wizard-nav {
      // Steps
      .wizard-steps {
        // Step
        .wizard-step {
          .wizard-label {
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            .wizard-icon {
              font-size: 3.75rem;
            }

            .wizard-title {
              margin-top: 0.75rem;
            }
          }

          .wizard-arrow {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

// Table and Mobile mode
@include media-breakpoint-down(md) {
  .wizard.wizard-1 {
  }
}

// Mobile mode
@include media-breakpoint-down(sm) {
  .wizard.wizard-1 {
    // Nav
    .wizard-nav {
      // Steps
      .wizard-steps {
        flex-direction: column;

        // Step
        .wizard-step {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;

          .wizard-label {
            flex-direction: row;
            display: flex;
            align-items: center;
            margin-left: 0;
            margin-right: 0;

            .wizard-icon {
              font-size: 1.5rem;
              margin-right: 1.25rem;
            }
          }

          .wizard-arrow {
            font-size: 1rem;

            &.last {
              display: block;
            }
          }
        }
      }
    }
  }
}
