.feedback-pill-notation {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  margin: 0 0.5rem 1rem 0;

  &.active {
    color: white;
    background-color: #0275d8;
  }
}
