.url-input {
  --background: #fff;
  --border-default: #E1E6F9;
  --border-active: #275EFE;
  --text-color: #646B8C;
  --placeholder-color: #BBC1E1;
  --icon: #275EFE;
  --close: #646B8C;
  --close-light: #A6ACCD;
  --close-background: #EFF2FB;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background: var(--background);
  box-shadow: inset 0 0 0 var(--border-width, 1px) var(--border, var(--border-default));
  transition: box-shadow .2s;
  --favicon-scale: 0;
  --icon-offset: 0px;
  --clear-x: 0px;
  --clear-swipe-left: 0px;
  --clear-swipe-x: 0;
  --clear-swipe: 0px;
  --clear-scale: 0;
  --clear-rotate: 0deg;
  --clear-opacity: 0;
  --clear-arrow-o: 1;
  --clear-arrow-x: 0px;
  --clear-arrow-y: 0px;
  --clear-arrow-offset: 4px;
  --clear-arrow-offset-second: 4px;
  --clear-line-array: 8.5px;
  --clear-line-offset: 27px;
  --clear-long-array: 8.5px;
  --clear-long-offset: 24px;

  &.clearing,
  &:focus-within {
    --border-width: 1.5px;
    --border: var(--border-active);
  }
  &.clearing {
    --close-background: transparent;
    --clear-arrow-stroke: var(--close-light);
  }
  &:hover p {
    text-decoration: underline;
  }
  .icon {
    cursor: pointer;
    background: none;
    border: none;
    margin: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 15px;
    svg,
    img {
      display: block;
      width: 18px;
      height: 18px;
    }
    svg {
      fill: var(--icon);
    }
    .favicon {
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(var(--favicon-scale)) translateZ(0);
    }
  }
  .text {
    flex-grow: 1;
    width: 100%;
    padding: 12px 44px 12px 12px;
    p {
      -webkit-appearance: none;
      line-height: 24px;
      background: none;
      border: none;
      outline: none;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      margin: 0;
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      color: var(--text-color);
    }
  }
}