@mixin ImagePreview($size) {
  width: $size;
}

.preview {
  .child {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border-radius: 50%;
  }
}

.image-preview-sm {
  @include ImagePreview(4rem);
}

.image-preview-md {
  @include ImagePreview(5rem);
}

.image-preview-lg {
  @include ImagePreview(7rem);
}
