.Droppable {
  position: relative;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  height: 100px;
  margin: 15px 0;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: inset rgba(201, 211, 219, 0.5) 0 0 0 2px, rgba(255, 255, 255, 0) 0 0 0 1px,
    rgba(201, 211, 219, 0.25) 20px 14px 24px;
  transition: box-shadow 250ms ease;

  > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 200px;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0.8;
    transition: opacity 300ms ease, transform 200ms ease;
    user-select: none;
    pointer-events: none;
  }

  &.dragging {
    > svg {
      opacity: 0.8;
    }
  }

  &.over {
    box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
    cursor: pointer;

    > svg {
      opacity: 1;
    }

    &.dropped {
      box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,
        rgba(201, 211, 219, 0.5) 20px 14px 24px;
    }
  }

  &.dropped {
    > svg {
      opacity: 0.2;
      transform: translate3d(-50%, 100%, 0) scale(0.8);
    }
  }

  .modules-list{
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      flex-shrink: 1;
      appearance: none;
      outline: none;
      border: 0;
      border-radius: 5px;
      box-shadow: var(--box-shadow);
      transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
      scale(var(--scale, 1));
      transition: box-shadow 300ms ease;
      &:hover {
        box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
      }
    }
  }
}
