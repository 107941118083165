//
// Breadcrumb
//

.breadcrumb {
  align-items: center;

  .breadcrumb-item {
    a {
      display: flex;
      align-items: center;
    }

    &.active,
    &:hover {
      i,
      a {
        transition: $transition-link;
        color: $primary !important;
      }
    }
  }

  &.breadcrumb-transparent {
    background-color: transparent;
    border-radius: 0;
  }

  &.breadcrumb-dot {
    .breadcrumb-item {
      display: flex;
      align-items: center;

      &:before {
        display: none;
      }

      &:after {
        display: block;
        content: ' ';
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: $gray-400;
        margin-left: $breadcrumb-item-padding;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
