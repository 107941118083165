.scroll-conversation {
  width: 100%;
  height: 70vh;
}

.download-image {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.chat-image:hover > .download-image {
  opacity: 1;
}

.paragraph {
  white-space: pre-line;
}
